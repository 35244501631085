import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import * as Layout2Col from "../components/layout/layout2col/layout2col"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Seo from "../components/seo/seo"

import BlogSubscribeForm from "../components/blog/blogSubscribeForm/blogSubscribeForm"
import BlogCategories from "../components/blog/blogCategories/blogCategories"
import ListItem from "../components/shared/listItem/listItem"
import Pagination from "../components/shared/pagination/pagination"

const Blog = ({ data, pageContext }) => {
  const { content, seo } = data.sanityBlogListing.tabs
  const posts = data.allSanityBlogPost
  const { currentPage, totalPages } = pageContext
  const steps = [
    {
      link: `blog`,
      title: "Blog",
    },
  ]

  return (
    <Layout>
      <Seo
        isBlogPost={false}
        title={content.title}
        path={pageContext.path}
        image={content.featuredImage?.asset.url}
        breadcrumbs={steps}
        {...seo}
      />
      <Breadcrumbs steps={steps} />
      <HeroSimple title={content.hero} subtitle={content.subtitle} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          <div className="grid">
            {posts.nodes.map(post => (
              <ListItem {...post.tabs.content} base="blog" key={post.tabs.content.title} />
            ))}
          </div>
          {totalPages > 1 && <Pagination base="blog" currentPage={currentPage} totalPages={totalPages} />}
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <BlogCategories />
          <BlogSubscribeForm hideOnMobile />
        </Layout2Col.Sidebar>
      </Layout2Col.Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    sanityBlogListing {
      tabs {
        content {
          hero
          subtitle
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityBlogPost(sort: { fields: tabs___content___publishDate, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        tabs {
          content {
            title
            subtitle
            excerpt
            publishDate
            category {
              title
              slug {
                current
              }
            }
            slug {
              current
            }
            featuredImage {
              asset {
                fluid(maxWidth: 580) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
